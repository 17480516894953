<template>
  <div class="hello" style="text-align:center">
    <div class="head">
      <div class="headLeft">
        <!-- <img style="width:85px;max-height:50px" v-if='!domain' src="https://wlzj-platform.posedu.com.cn/manage/uploads/20210818/1bij1mf9tft4c8dkwmhb928ccb9q909g.png"> -->
        <img style="width:85px;max-height:50px" :src="infoList.doorInfo.logo">
        <div v-if='!domain'>
          <h2 style="margin-bottom: -5px;
    margin-top: 0px;
    letter-spacing: 2px;
    margin-left: 10px;">位来教育大赛展示平台</h2>
          <p style="margin-bottom: 0;font-size:11px">Wei Lai Education Competition Display Platform</p>
        </div>
        <div v-else>
          <h2 style="margin-bottom: -5px;
    margin-top: 0px;
    letter-spacing: 2px;
    margin-left: 10px;" v-html='infoList.doorInfo.name'></h2>
        </div>
      </div>
      <div class="headRight" style="position: relative;">
        <router-link :to='{path:"/home"}' style='margin-left:20px'>首页</router-link>
        <router-link :to='{path:"/notice",query:{info:domain}}' style='margin-left:30px'>赛事通知</router-link>
        <!-- <router-link  :to='{path:"/matchNew",query:{info:domain}}'  style='margin-left:30px'>报名通道</router-link> -->
        <a @click="goAnchor('#matchnews')" style='margin-left:30px'>报名通道</a>
        <a href='https://match.posedu.com.cn/mms/#/login' target='_blank' style='margin-left:30px'>省级管理员</a>

      </div>
    </div>
    <p style="min-height:75.2vh;border: 1px solid #ccc;" v-html="infoList1"></p>
    <div class="footer">
      <div style="    margin-left: -147px;
    margin-top: 24px;">
        <p v-if='domain== "gansu"'>版权所有:甘肃省教育厅</p>
        <p v-if='domain== "gansu"'>技术支持:北京位来教育科技有限公司 CopyRight 2021</p>
        <p v-else>版权所有:北京位来教育科技有限公司 CopyRight 2021</p>
        <p style="
    text-align: left;
">备案号 :京ICP备20011457号-1</p>


        <!-- <p>商务合作：400-4333-222</p>
          <p>公司地址：北京市海淀区</p> -->
      </div>
      <div style="    margin-top: 10px;">
        <img src="../assets/二维码.jpg" style="width:80px;height:80px">
        <p style="margin-top: 0;">官方公众号</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        infoList: '',
        infoList1: '',
        domain: '',
        url1: '',
        url2: ''
      }
    },
    mounted() {
      this.infoList1 = this.$route.query.info
      this.domain = this.getDomain()
      if (this.domain) {
        this.getDoorInfo()

      } else {
        this.getSchoolimg()

      }
    },
    methods: {
      getDomain() {
        let domain = window.location.hostname.split(".")
        console.log("测试domain", domain)
        if (domain.length == 5) {
          domain = domain[0]
        } else {
          domain = ''
        }
        return domain
      },
      getSchoolimg() {
        this.$http({
          method: 'post',
          url: 'https://api.match.posedu.com.cn/match/match/getSchoolByDomain',

          data: {
            //  domain: ""
          }
        }).then(res => {
          let schoolImg = res.data.data
          schoolImg.forEach(item => {
            // console.log('item',item.logo);
            if (item.logo != null && item.logo.search("http") == -1) {
              let imgurl = this.imgUrl + item.logo
              this.schoollogoImg.push(imgurl)
            } else if (item.logo != '') {
              this.schoollogoImg.push(item.logo)
            }
          })
          console.log(this.schoollogoImg)

        })
      },
      getDoorInfo() {
        this.$http({
          url: 'https://api.match.posedu.com.cn/match/match/getDoorInfo',
          //  url:'http://192.168.2.132:8080/match/match/getSchoolByDomain',

          method: 'post',
          data: {
            code: this.domain

          }
        }).then(res => {
          if (res.data.data.doorInfo.name) {
            let text = res.data.data.doorInfo.name
            let replace = text.replace(/<[^>]+>/g, "");
            document.title = replace
          }

          this.infoList = res.data.data
          //    this.schoollogoImg = res.data.data.schoolList
          //    this.videoList = JSON.parse(res.data.data.doorInfo.videoUrl)
          //      this.videoList.forEach(item=>{
          //      console.log(item.name);
          //      item.name = item.name.replace('.mp4','')
          //      console.log(item.name);

          //  })
          //    this.linkList =  res.data.data.friendlylinks
          //    this.banner = JSON.parse(res.data.data.doorInfo.banner)
          this.url1 = res.data.data.doorInfo.platform
          this.url2 = res.data.data.doorInfo.aboutUs


        })
        //  this.$http({
        //    url:`https://match.posedu.com.cn/match/dynamic/matchPageDynamicList`,
        //    method:'post',
        //    data:{
        //      page:1,
        //      limit:100,
        //      domain:this.domain,
        //      show:0
        //    }
        //  }).then(res=>{
        //    this.matchDynamic = res.data.data.list
        //  })
        //   this.$http({
        //    url:`https://match.posedu.com.cn/match/appendix/list`,
        //    method:'post',
        //    data:{
        //      page:1,
        //      limit:100,
        //      domain:this.domain,
        //    }
        //  }).then(res=>{
        //    this.objnews = res.data.data.list
        //    console.log('this,newsssssssss',this.objnews);
        //  })
      },
      //锚点跳转
      goAnchor() {
        this.$router.push({
          path: "/",
          query: {
            mao: 1
          }
        })
        // var anchor = this.$el.querySelector(selector) // 参数为要跳转到的元素id,是(#id)
        // document.body.scrollTop = anchor.offsetTop; // chrome
        // document.documentElement.scrollTop = anchor.offsetTop; // firefox
      },
    }
  }
</script>

<style scoped>
  .hello {
    width: 75%;
    margin: 0 auto;
  }

  .head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px !important;
    border-bottom: 1px solid #ccc;
    width: 78.5vw;
    margin-left: -18%;
    padding: 0 14%;
  }

  .headRight,
  .headLeft {
    display: flex;
    align-items: center;
  }

  .scrollimg {
    width: 99.5vw;
    margin-left: -18%;
  }

  .lexrsda {
    display: flex;
    flex-wrap: wrap;
  }

  .lexrsda a {
    width: 50%;
  }

  video {
    height: 81%;
    width: 100%;
  }

  /* .banner .slideBox .bd img {
    width: 100%;
    display: block;
}
.banner {
    width: 99vw;
    overflow: hidden;
}
.banner .slideBox {
    width: 100%;
    overflow: hidden;
    position: relative;
}
.banner .slideBox .bd {
    position: relative;
    height: 100%;
    z-index: 0;
}
.banner .slideBox .bd ul {
    width: 100%;
    margin: 0;
}
.banner .slideBox .bd li {
    zoom: 1;
    vertical-align: middle;
} */
  .icon {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 11px;
  }

  .matchhead {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 9px;
  }

  .matchnews {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    margin-top: 20px;
  }

  .info {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    height: 254px;
  }

  .footer {
    background-color: #f5f5f5;
    width: 99.5vw;
    margin-left: -18%;
    display: flex;
    justify-content: space-around;
  }

  .headRight>p {
    margin-right: 40px;
  }

  .schoolP {
    max-height: 600px;
    overflow-y: scroll;
    padding: 9px !important;
    display: flex;
    flex-wrap: wrap;
  }

  .contant {
    width: 19.3%;
    margin-bottom: 60px;
    cursor: pointer;
    margin: 35px;
    /* height: 500px; */
    /* padding: 0 37px; */
  }

  .contant .info p:hover {
    color: cornflowerblue
  }

  .contant img {
    width: 100%;
    height: 100%;
  }

  .img {
    display: flex;
  }

  .img img {
    /* width: 50px; */
    height: 47px;
    margin-right: 17px;
  }

  .info p {
    border: 1px solid #d4d0d0;
    border-top: 0;
    margin-top: -3px;
    height: 38px;
    padding-top: 11px;
  }

  .head {
    margin-bottom: 10px;
  }

  a {
    text-decoration: none;
    color: black;
  }

  .inputicon {
    width: 24px;
    height: 24px;
    background: url(../assets/搜索.png) no-repeat center center;
    background-size: 100%;
    position: absolute;
    right: 10px;
  }

  .school a {
    color: #0e9ad9;
    padding: 5px 0px;
  }

  .lexrsda p {
    width: 50%;
  }

  div#slideBox {
    width: 100vw;
    margin-left: -18%;
  }

  #slideBox img {
    width: 100%;
  }

  .el-carousel.el-carousel--horizontal.el-carousel--card h2 {
    text-align: center;
    background-color: #fff;
    padding-top: 24px;
    margin-top: -16px;
    padding-bottom: 22px;
    margin-bottom: -10px;
    font-size: 22px;
  }

  .el-carousel__arrow,
  .el-carousel__arrow:hover {
    background-color: green;
    color: blue;
  }
</style>